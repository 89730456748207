.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.main-hero {
    background: url("../../../public/pizza cookies.JPG") no-repeat left center;
    background-size: cover;
    opacity: .9;
}

.article-title {
    text-transform: uppercase;
    font-family: 'Amatic SC', serif;
}

.reviewer-name {
    text-transform: uppercase;
    font-family: 'Amatic SC', serif;
}

.review-text {
    font-size: 1rem;
}

.darkBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1040; /* Just below the modal */
}

.close-modal {
    position: absolute;
    right: -25px;
    top: -25px;
}
