#navbarSupportedContent {
    font-family: "Amatic SC", Arial, serif;
    font-size: 28px;
    text-transform: uppercase;
}

#header .nav-link {
    color: #655A4F !important;
}

#header .nav-link + .active {
    color: #08A4BD !important;
}

#header :hover {
    color: #08A4BD !important;
}

.navbar-brand {
    width: 150px;
    margin-left: 10px !important;
}

.banner-content p {
    margin-bottom: 0;
}

.nav-block {
    min-width: 300px;
}
